import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Grid} from '../components/responsive/'
import H1 from '../components/h1'
import H2 from '../components/h2'
import H3 from '../components/h3'
import Content from '../components/content'
import Image from '../components/image'
import Header from '../components/header/header'
import ContainerFooter from '../components/containerFooter'
import Footer from '../components/footer/footer'
import Wrap from '../components/wrap'
import Metadata from '../components/metadata'
import './index.css'

export default ({ data }) => (
  <div>
  <Metadata title='About DEN'/>
  <Wrap>
  <Header active='about'/>
  <Container backgroundColor='#FFF' paddingTop='1em' marginTop='4.5em'>
  <H1 info='Drupal DEN ロゴマーク' fluid={data.about.childImageSharp.fluid}>
  About DEN
  </H1>
  <H2>
  Drupalグループ DEN (田) とは
  </H2>
  <H3>
  21世紀、デジタルの『産業の米』はDrupal
  </H3>
  <Row>
    <Grid pc='7' sp='12'>
      <Content>
      戦後、製造業を支えた鉄鋼や半導体は『産業の米』と呼ばれました。日本はこの製造業を発展させて世界中に多くの製品をおくりだしました。<br/>
  21世紀は、製造業からデジタルが作業を大きく左右する時代になりました。デジタルテクノロジーによる情報やマーケティングによって大きく世の中がかわっていきます。その中でDrupalは様ざなデジタルデバイスのハブになり、デジタルマーケティングも主導するツールです。私たちはデジタルテクノロジーの中心になれるDrupalはまさにデジタルの『産業の米』になると確信しています。<br/>
  Drupalグループ DENは日本でDrupalを普及させていくことで、日本の「産業の米」を育てていくコミュニティ = DEN（田）を広げていきます。
      </Content>
    </Grid>
    <Grid pc='5' sp='12'>
      <Image fluid={data.about2.childImageSharp.fluid}/>
    </Grid>
  </Row>
  <H2>
  コミュニティ
  </H2>
  <H3>
  全国にコミュニティ = DEN を広げています。
  </H3>
  <Content>
  全国に田んぼが広がることで農業が広まるように、全国にDENを作ることでDrupalの活用を促進させていきます。<br/><br/>
  Drupal Meetup 羽田 <a href='https://drupal-meetup-haneda.connpass.com/' target='_blank' rel="noopener noreferrer">https://drupal-meetup-haneda.connpass.com</a><br/>
  Drupal Meetup 梅田 <a href='https://drupal-meetup-umeda.connpass.com/' target='_blank' rel="noopener noreferrer">https://drupal-meetup-umeda.connpass.com</a><br/>
  Drupal Meetup 豊田 <a href='https://drupal-meetup-toyota.connpass.com/' target='_blank' rel="noopener noreferrer">https://drupal-meetup-toyota.connpass.com</a>
  </Content>
  <H2>
  これまでの活動と展望
  </H2>
  <Row>
    <Grid pc='5' sp='12'>
      <Content>
      2017年　Drupal Meetup 羽田 開始<br/>
      2018年　Drupal Camp DEN 開始<br/>
      2019年　Drupal Camp DEN 二回目<br/>
      2020年　Drupal Camp DEN 三回目<br/>
      2021年　DrupalCON Tokyo
      </Content>
    </Grid>
    <Grid pc='7' sp='12'>
      <Image fluid={data.about3.childImageSharp.fluid}/>
    </Grid>
  </Row>
  <ContainerFooter/>
  </Container>
  <Footer/>
  </Wrap>
  </div>
)

export const query = graphql`
  query {
    about:file(relativePath: { eq: "About.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    about2:file(relativePath: { eq: "About2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    about3:file(relativePath: { eq: "About3.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
